<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel" persistent>
    <v-form ref="form" class="pa-6 text-start">
      <div class="d-flex mb-6">
        <v-img :src="require(`@/assets/icons/claim-icon.svg`)" max-width="24px" contain />
        <div
          class="text-medium font-weight-medium ml-2"
          :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'"
        >
          {{ claim.name }}
        </div>
      </div>
      <div class="text-body-2 text-regular mb-1">Time to start claiming token</div>
      <v-row>
        <v-col cols="9" sm="5" class="pb-0">
          <p class="text-caption mb-1">Date *</p>
          <v-text-field
            :disabled="loading"
            v-model="activeDate"
            type="date"
            :rules="[$rules.required, $rules.date]"
            dense
            outlined
            rounded
            @input="dateError = false"
          />
        </v-col>
        <v-col cols="9" sm="5" class="pb-0">
          <p class="text-caption mb-2">Hour *</p>
          <v-text-field
            v-model="activeHour"
            :disabled="loading"
            type="time"
            :rules="[$rules.required]"
            dense
            outlined
            rounded
            @input="dateError = false"
          />
        </v-col>
      </v-row>
      <div class="red--text" v-if="dateError">Must be the future time</div>
      <div class="d-flex justify-space-between mt-2">
        <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
        <v-btn class="primary" rounded min-width="100px" @click="save" :loading="loading">Save</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props: {
    state: Boolean,
    claim: Object,
  },
  components: {},
  computed: {
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const combinedTime = Date.parse(new Date(this.activeDate + ' ' + this.activeHour)) / 1000
        if (moment(combinedTime * 1000).isBefore(moment())) {
          this.dateError = true
          return
        }
        const res = await this.multiClaimHandler.changeActiveDate(this.claim.id, combinedTime)
        if (res) {
          this.$emit('updateDone', combinedTime)
          this.$alert.success('Update active date successed')
        } else {
          this.$alert.error('Update active date  failed')
        }
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
    async resetDefault() {
      if (this.$refs.form && !this.loading) {
        await this.$refs.form.reset()
      }
      if (!this.loading) {
        this.activeDate = moment.unix(this.claim.activeTime).format('YYYY-MM-DD')
        this.activeHour = moment.unix(this.claim.activeTime).format('HH:mm')
      }
      this.dateError = false
    },
  },
  created() {
    this.resetDefault()
  },
  data() {
    return {
      activeDate: '',
      activeHour: '',
      dialog: false,
      loading: false,
      dateError: false,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>